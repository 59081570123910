<ng-container *ngIf="((isAuthenticated | async) || isItem()) && contextMenuObject">
  <ng-container *ngFor="let entry of (getStandAloneMenuEntries() | async)">
    <ng-container *ngComponentOutlet="entry; injector: objectInjector;"></ng-container>
  </ng-container>
  <div ngbDropdown #itemOptions="ngbDropdown" placement="bottom-right"
       class="float-right ml-1" [ngClass]="optionCount === 0 ? 'd-none' : 'd-inline-block'">
    <button class="btn btn-outline-primary" id="context-menu" ngbDropdownToggle>
      <i class="fas fa-ellipsis-h" aria-hidden="true"></i>
    </button>
    <div id="itemOptionsDropdownMenu" aria-labelledby="context-menu" ngbDropdownMenu>
      <h6 class="dropdown-header">{{'context-menu.actions.label' | translate}}</h6>
      <ng-container *ngFor="let entry of (getContextMenuEntries() | async)">
        <ng-container *ngComponentOutlet="entry; injector: objectInjector;"></ng-container>
      </ng-container>
    </div>
  </div>

</ng-container>
