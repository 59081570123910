<button *ngIf="!isContextMenu && (isAuthorized$ | async)" class="edit-button btn btn-dark btn-sm" (click)="createNewVersion()"
  [disabled]="disableNewVersionButton$ | async" [ngbTooltip]="tooltipMsg$ | async | translate" role="button" [title]="tooltipMsg$ | async |translate"
  [attr.aria-label]="tooltipMsg$ | async | translate">
  <i class="fas fa-code-branch fa-fw"></i>
</button>


<button *ngIf="isContextMenu && (isAuthorized$ | async)" class="dropdown-item" (click)="createNewVersion()">
  <span [innerHtml]="tooltipMsg$ | async | translate">
  </span>
</button>