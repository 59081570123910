<div class="container-fluid-ds">
  <div class = "row d-flex justify-content-center">
    <div *ngIf="isLoading$ | async">
      <ds-loading message="{{'loading.default' | translate}}"></ds-loading>
    </div>
   <div *ngFor="let counter of (counterData$ | async); let i = index"
      class="px-4 py-1 d-flex flex-column fvg-counter" style="flex: 1 0 100px" [ngClass]="getFvgCounterColor(i)">
        <div (click)="goToLink(counter.link)"
          [ngStyle]="{'cursor': counter.link? 'pointer' : 'inherit'}"
          class="col d-flex justify-content-center text-center align-items-center">
            <i [ngClass]="counter.icon"></i>
        </div>
        <div (click)="goToLink(counter.link)"
          [ngStyle]="{'cursor': counter.link? 'pointer' : 'inherit'}"
          class="col d-flex justify-content-center text-center align-items-center">
            {{'explore.counters-section.' + counter.label | translate}}
        </div>
        <div (click)="goToLink(counter.link)"
          [ngStyle]="{'cursor': counter.link? 'pointer' : 'inherit'}"
          class="col d-flex justify-content-center text-center align-items-center">
            <b>{{counter.count}}</b>
         </div>
    </div>
  </div>
</div>
