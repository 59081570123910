<button class="action-buttons btn btn-success mt-1 mb-3"
			*ngIf="!isSelected && !!object"
            ngbTooltip="{{'manage.relationships.select' | translate}}"
            (click)="emitAction('select')"
            [disabled]="isProcessing()"
            >
      <span *ngIf="isProcessingSelect"><i class='fas fa-circle-notch fa-spin'></i> {{'loading.default' | translate}}</span>
      <span *ngIf="!isProcessingSelect"> {{"manage.relationships.select" | translate}}</span>

</button>
<button class="action-buttons btn btn-primary mt-1 mb-3"
			*ngIf="!!isSelected && !!object"
            ngbTooltip="{{'manage.relationships.unselect' | translate}}"
            (click)="emitAction('unselect')"
            [disabled]="isProcessing()"
            >
      <span *ngIf="isProcessingUnselect"><i class='fas fa-circle-notch fa-spin'></i> {{'loading.default' | translate}}</span>
      <span *ngIf="!isProcessingUnselect"> {{"manage.relationships.unselect" | translate}}</span>

</button>
<button class="action-buttons btn btn-danger mt-1 mb-3"
			*ngIf="!isHidden && !!object"
            ngbTooltip="{{'manage.relationships.hide' | translate}}"
            (click)="emitAction('hide')"
            [disabled]="isProcessing()"
            >
      <span *ngIf="isProcessingHide"><i class='fas fa-circle-notch fa-spin'></i> {{'loading.default' | translate}}</span>
      <span *ngIf="!isProcessingHide"> {{"manage.relationships.hide" | translate}}</span>
</button>
<button class="action-buttons btn btn-warning mt-1 mb-3"
			*ngIf="!!isHidden && !!object"
            ngbTooltip="{{'manage.relationships.unhide' | translate}}"
            (click)="emitAction('unhide')"
            [disabled]="isProcessing()"
            >
      <span *ngIf="isProcessingUnhide"><i class='fas fa-circle-notch fa-spin'></i> {{'loading.default' | translate}}</span>
      <span *ngIf="!isProcessingUnhide"> {{"manage.relationships.unhide" | translate}}</span>

</button>
