<div class="container-fluid-ds mx-4">
  <ds-suggestions-notification></ds-suggestions-notification>
  <ds-my-dspace-new-submission *dsShowOnlyForRole="[roleTypeEnum.Submitter]"></ds-my-dspace-new-submission>
</div>

<ds-themed-search *ngIf="configuration && context"
           [configuration]="configuration"
           [configurationList]="(configurationList$ | async)"
           [context]="context"
           [viewModeList]="viewModeList"
           [projection]="projection">
  <ds-my-dspace-new-bulk-import additionalSearchFormOptions></ds-my-dspace-new-bulk-import>
</ds-themed-search>
