import { TextRowSection } from '../../../../core/layout/models/section.model';
import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { Site } from '../../../../core/shared/site.model';
import { LocaleService } from '../../../../core/locale/locale.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'ds-text-section',
  templateUrl: './text-section.component.html',
  styleUrls: ['./text-section.component.scss'],
})
export class TextSectionComponent implements OnInit {

  content: SafeHtml;

  @Input()
  trustedHtml = true;

  @Input()
  sectionId: string;

  @Input()
  textRowSection: TextRowSection;

  @Input()
  site: Site;

  constructor(
    private locale: LocaleService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    if (this.site?.metadata && this.textRowSection.content) {
      const mdv = this.site.firstMetadataValue(this.textRowSection.content,
        { language: this.locale.getCurrentLanguageCode() });

      if (this.trustedHtml) {
        this.content = this.sanitizer.bypassSecurityTrustHtml(mdv ?? '');
      } else {
        this.content = this.sanitizer.sanitize(SecurityContext.HTML, mdv ?? '');
      }

      // console.log(this.textRowSection.content + ': ' + mdv);
    }
  }
}
