<div class="row d-flex align-items-center mb-2" *ngIf="remark">
  <div class="col text-left">
    <div
      #metricChild
      class="__dimensions_badge_embed__"
      [attr.data-hide-zero-citations]="isListElement"
      [attr.data-pmid]="
      (remark | dsListMetricProps: 'data-doi':isListElement)
          ? null
          : (remark | dsListMetricProps: 'data-pmid':isListElement)
      "
      [attr.data-doi]="remark | dsListMetricProps: 'data-doi':isListElement"
      [attr.data-style]="remark | dsListMetricProps: 'data-style':isListElement"
      [attr.data-legend]="
        remark | dsListMetricProps: 'data-legend':isListElement
      "
    ></div>
  </div>
  <div class="col-7" *ngIf="!hideLabel">
    <div class="font-weight-bold text-capitalize">
      {{ metric.metricType | translate }}
    </div>
  </div>
</div>
