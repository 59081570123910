<div class="justify-content-center" *ngIf="remark">
  <div *ngIf="(remark | dsListMetricProps: 'type':isListElement) == 'Person'; else publication" [ngClass]="{'person-type-plumX': isListElement}">
    <a
      [href]="remark | dsListMetricProps: 'href':isListElement"
      class="plumx-person"
      data-site="plum"
      [attr.data-lang]="remark | dsListMetricProps: 'data-lang':isListElement"
      [attr.data-no-name]="remark | dsListMetricProps: 'data-no-name':isListElement"
      [attr.data-num-artifacts]="remark | dsListMetricProps: 'data-num-artifacts':isListElement"
      [attr.data-width]="remark | dsListMetricProps: 'data-width':isListElement"
      [attr.data-no-description]="remark | dsListMetricProps: 'data-no-description':isListElement"
      [attr.data-no-stats]="remark | dsListMetricProps: 'data-no-stats':isListElement"
      [attr.data-no-thumbnail]="remark | dsListMetricProps: 'data-no-thumbnail':isListElement"
      [attr.data-no-artifacts]="remark | dsListMetricProps: 'data-no-artifacts':isListElement"
    >
      {{ remark | dsListMetricProps: "placeholder":isListElement }}
    </a>
  </div>
  <ng-template #publication>
    <a
      [attr.data-hide-when-empty]="isListElement"
      [href]="remark | dsListMetricProps: 'href':isListElement"
      class="plumx-plum-print-popup"
    >
    {{ remark | dsListMetricProps: "placeholder":isListElement }}
    </a>
  </ng-template>
</div>
