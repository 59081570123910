<div *ngIf="securityConfigLevel && !(securityConfigLevel.length == 1 &&  securityConfigLevel.indexOf(0)>-1)"
     class="switch-container">
  <div *ngFor="let secLevel of securityLevelsMap">
    <div class="switch-opt" (click)="changeSelectedSecurity($any(secLevel).value) "
         [ngStyle]="{'background-color': securityLevel === $any(secLevel).value ? 'white' : 'rgb(204,204,204)' }">
      <i [class]="$any(secLevel).icon"
         [ngStyle]="{'color': securityLevel == $any(secLevel).value ? $any(secLevel).color : '' }"></i>
    </div>
  </div>
</div>
