<div class="col-12">
  <h2 *ngIf="showTitle">{{ 'explore.facet-section.title' | translate }}</h2>
  <div class="row">
    <div *ngFor="let facet of (facets$ | async)" class="col-{{getFacetsBoxCol(facet)}} mb-4">
      <div *ngIf="facet.filterType.includes('chart'); else notChartFacet">
        <strong *ngIf="showTitle">{{'explore.index.' + facet.name | translate}}</strong>
        <ds-search-chart [filter]="facet" [inPlaceSearch]="false"></ds-search-chart>
      </div>
      <ng-template #notChartFacet>
        <div class="card">
          <div *ngIf="showTitle" class="card-header">{{'explore.index.' + facet.name | translate}}</div>
          <ul class="list-group list-group-flush">
            <li *ngFor="let facetValue of facet._embedded.values" class="list-group-item">
              <a [routerLink]="[searchService.getSearchLink()]"
                 [queryParams]="getSearchQueryParams(facet, facetValue)">
                {{facetValue.label}}
              </a>
              <span class="badge badge-secondary float-right">{{facetValue.count}}</span>
            </li>
          </ul>
        </div>
      </ng-template>
    </div>
  </div>
</div>
