<div class="chart-container"
     [class.pr-3]="enableScrollToRight && hasScrollbar">
  <div class="scroll-class" #chartArea #chartContainer>
    <ng-container *ngIf="type === defaultChartType; else horizontalChartType" >
      <ngx-charts-bar-vertical
        [view]="view?.length == 0 ? [chartContainer.offsetWidth] : view"
        [results]="getResults()"
        [animations]="animations"
        [legend]="legend"
        [legendTitle]="legendTitle"
        [legendPosition]="legendPosition"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel | translate"
        [yAxisLabel]="yAxisLabel | translate"
        [showYAxisLabel]="hasTranslation(yAxisLabel)"
        [showXAxisLabel]="hasTranslation(xAxisLabel)"
        (select)="onSelect($event)">
      </ngx-charts-bar-vertical>
    </ng-container>
    <ng-template #horizontalChartType>
      <ngx-charts-bar-horizontal
        [view]="view?.length == 0 ? [chartContainer.offsetWidth] : view"
        [animations]="animations"
        [results]="getResults()"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel | translate"
        [yAxisLabel]="yAxisLabel | translate"
        [showYAxisLabel]="hasTranslation(yAxisLabel)"
        [showXAxisLabel]="hasTranslation(xAxisLabel)"
        [legend]="legend"
        [legendTitle]="legendTitle"
        [legendPosition]="legendPosition"
        (select)="onSelect($event)"
       >
      </ngx-charts-bar-horizontal>
    </ng-template>
  </div>
  <button *ngIf="enableScrollToLeft"
          type="button"
          class="btn btn-link load-more-left"
          [title]="'chart.load-more' | translate"
          [attr.aria-label]="'chart.load-more' | translate"
          [disabled]="enableScrollToLeft && (isLastPage | async)"
          [ngClass]="{ 'no-scrollbar': !hasScrollbar }"
          (click)="loadMoreData()">
    <i class="fas fa-2x fa-caret-left"></i>
  </button>
  <button *ngIf="enableScrollToRight"
          type="button"
          class="btn btn-link load-more-right"
          [title]="'chart.load-more' | translate"
          [attr.aria-label]="'chart.load-more' | translate"
          [disabled]="enableScrollToRight && (isLastPage | async)"
          [ngClass]="{ 'no-scrollbar': !hasScrollbar, 'load-more-right-with-scrollbar': hasScrollbar }"
          (click)="loadMoreData()">
    <i class="fas fa-2x fa-caret-right"></i>
  </button>
</div>
